import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/night-of-the-creeps-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1986 zombie movie, Night of the Creeps"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 37</h1>
                    <h2>Night of the Creeps</h2>
                    <h3>March 4, 2024</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Fred Dekker // <b>Starring:</b> Jason Lively &amp; Tom Atkins</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/36-Dawn-of-the-Dead-e2fvgqn/a-aavrs7m" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe episode 36, Dawn of the Dead"></iframe>
                        </div>
                        {/* <p><Link to="/transcripts/my-bloody-valentine">Read the episode transcript</Link></p> */}
                        <p>Bryan and Dave take a good long look at Fred Dekker's 1986 cult favorite, Night of the Creeps and come away with a vastly different view of it than when they watched it as kids. What could possibly change their minds so significantly? It's a pastiche of 50's and 60's drive in tropes filtered through a 1980's horror movie lens but unfortunately, given the way things have shaken out in modern times, some of those tropes haven't aged well at all. Thankfully, Tom Atkins is here to save everything. Thrill me!</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/dawn-of-the-dead">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)